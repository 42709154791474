<template>
  <div>
    <p class="ml-3 mt-3">
      User Id: {{ yourId }}<br/>
      Room Id: <input placeholder="room name" v-model="roomName"/>
    </p>
    <button @click="authorize" type="button" class="btn btn-primary btn-lg">
      Authorize
    </button>
    <br/>
    <video ref="yourVideo" autoplay muted playsinline></video>
    <br/>
    <button ref="btLeave" @click="leaveRoom" type="button" class="btn btn-warning btn-lg" v-if="otherStream">
      Leave Call
    </button>
    <button ref="btRespond" @click="respondToCall" type="button" class="btn btn-danger btn-lg" v-else-if="offerMsg">
      Respond
    </button>
    <button ref="btCalling" type="button" class="btn btn-secondary btn-lg" v-else-if="sentOffer">
      Calling
    </button>
    <button ref="btCall" @click="call" type="button" class="btn btn-primary btn-lg" :disabled="!pc" v-else>
      Call
    </button>
    <br/>
    <video ref="friendsVideo" autoplay playsinline></video>
    <br/>
  </div>
</template>

<script>
import { database } from '@/services/db';
import { getLog } from "@/services/log";
let log = getLog('test');

export default {
  name: 'app',
  data() {
    return {
      pc: null,
      yourId: 0,
      rootDb: null,
      roomName: "room1",
      sentOffer: false,
      offerMsg: null,
      otherStream: null,
    }
  },
  mounted() {
    this.yourId = Math.floor(Math.random()*1000000000);
    log.log("userid=", this.yourId);
  },
  methods: {
    createRTC() {
      this.rootDb = database.ref(`LiveCalls/${this.roomName}`);

      let that = this;
      //Create an account on Viagenie (http://numb.viagenie.ca/), and replace {'urls': 'turn:numb.viagenie.ca','credential': 'websitebeaver','username': 'websitebeaver@email.com'} with the information from your account
      var servers = {'iceServers': [
        {'urls': 'stun:stun.services.mozilla.com'},
        {'urls': 'stun:stun.l.google.com:19302'}, 
        {'urls': 'stun:global.stun.twilio.com:3478?transport=udp' },
        {'urls': 'turn:numb.viagenie.ca','credential': 'beaver','username': 'webrtc.websitebeaver@gmail.com'}]};
      this.pc = new RTCPeerConnection(servers);
      // On ice candidate
      this.pc.onicecandidate = (event) => {
        log.log("pc on icecandidate", event);
        if (event.candidate)
          that.sendMessage(that.yourId, JSON.stringify({'ice': event.candidate}))
        else
          log.log("Sent All Ice");
      };
      // On add stream
      this.pc.onaddstream = (event) => {
        log.log("pc on onaddstream", event);
        that.otherStream = event.stream;
        that.$refs.friendsVideo.srcObject = event.stream;
      };
      // On message on firebase
      this.rootDb.on('child_added', (data) => {
        var msg = JSON.parse(data.val().message);
        var sender = data.val().sender;
        if (sender != that.yourId) {
          log.log("new message in fb", msg);
          if (msg.ice != undefined)
            that.pc.addIceCandidate(new RTCIceCandidate(msg.ice));
          else if (msg.sdp.type == "offer")
            that.offerMsg = msg;
          else if (msg.sdp.type == "answer")
            that.pc.setRemoteDescription(new RTCSessionDescription(msg.sdp));
          else
            log.log("Other message", msg);
        }
      });
    },
    authorize() {
      this.createRTC();

      let that = this;
      navigator.mediaDevices.getUserMedia({audio:true, video:true})
        .then(stream => that.$refs.yourVideo.srcObject = stream)
        .then(stream => that.pc.addStream(stream))
        .then(() => {
          log.log("Authorization complete");
        });
    },
    sendMessage(senderId, data) {
      log.log("sendMessage", senderId, data);
      var msg = this.rootDb.push({ sender: senderId, message: data });
      msg.remove();
    },
    async call() {
      let that = this;
      let response = await confirm("Please make sure the other user has pressed authorize before moving ahead. Continue?");
      if (response)
        this.pc.createOffer()
          .then(offer => this.pc.setLocalDescription(offer))
          .then(() => this.sendMessage(this.yourId, JSON.stringify({'sdp': this.pc.localDescription})) )
          .then(() => {
            log.log("Offer sent");
            that.sentOffer = true;
          });
    },
    respondToCall() {
      let that = this;
      this.pc.setRemoteDescription(new RTCSessionDescription(this.offerMsg.sdp))
        .then(() => that.pc.createAnswer())
        .then(answer => that.pc.setLocalDescription(answer))
        .then(() => that.sendMessage(that.yourId, JSON.stringify({'sdp': that.pc.localDescription})))
        .then(() => that.offerMsg = null);
    },
    leaveRoom() {
      this.sendMessage(this.yourId, JSON.stringify({'bye':true, 'sdp': this.pc.localDescription}));
      this.pc.close();
      this.pc = null;
      this.otherStream = null;
    }
  }
}
</script>

<style scoped>

video {
  background-color: #ddd;
  border-radius: 7px;
  margin: 10px 0px 0px 10px;
  width: 320px;
  height: 240px;
}
button {
  margin: 5px 0px 0px 10px !important;
  width: 320px;
}

</style>